@import '../../../Content/WebGiving/webgiving_theme/variables.less';

@download-icon-size: 84px;

.download-icon {
	margin: 0 auto;
	width: @download-icon-size;
	height: @download-icon-size;
	border-radius: 50%;
	background-color: @gray-lightest;
}

.lead {
	margin: 20px 10px 34px;
}

.panel-footer {
	background-color: @panel-bg;
}

.send-this-page-btn {
	margin: 20px 0 10px;
}
