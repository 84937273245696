@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.download-links {
	text-align: center;
	margin-bottom: 20px;

	@media @small {
		flex-direction: column;
		align-items: center;
	}
}

.download-link {
	text-decoration: none;
	color: transparent;

	&:active,
	&:focus,
	&:hover {
		color: transparent;
		text-decoration: none;
	}

	&:not(:last-child) {
		margin-right: 10px;

		@media @small {
			margin-bottom: 10px;
		}
	}
}
